// extracted by mini-css-extract-plugin
export var publisherButton = "style-module--publisher-button--luTEc";
export var publisherComics = "style-module--publisher-comics--8iGJ2";
export var publisherCover = "style-module--publisher-cover--5lJUh";
export var publisherCoverWrapper = "style-module--publisher-cover-wrapper--brT5d";
export var publisherDetail = "style-module--publisher-detail--aOxAF";
export var publisherInfo = "style-module--publisher-info--fhbGP";
export var publisherLink = "style-module--publisher-link--p+36E";
export var publisherPage = "style-module--publisher-page--Fg6-6";
export var publisherSubtitle = "style-module--publisher-subtitle--3IjvJ";
export var publisherTech = "style-module--publisher-tech--7B3a2";
export var publisherTechTitle = "style-module--publisher-tech-title--Laund";
export var publisherTitle = "style-module--publisher-title--gtcX-";
export var publisherTop = "style-module--publisher-top--VJCGt";